import * as React from "react";

const IndexPage = () => (
  <main className="flex flex-col w-screen h-screen items-center md:px-4 text-center">
    <div className="flex flex-col justify-between items-center md:w-1/3 h-screen bg-slate-400 p-8">
      <div className="space-y-8 mt-64 sm:mt-96">
        <h1 className="text-3xl font-bold text-slate-900">Nox Forest</h1>
        <p className="text-slate-700">
          Custom software engineering solutions for the internet of tomorrow
        </p>
        <a
          href="mailto:noxforestllc@gmail.com"
          className="block text-sm text-slate-700 underline"
        >
          Send inquiry
        </a>
      </div>
      <footer className="text-xs text-slate-600">
        &copy; 2022, Nox Forest LLC
      </footer>
    </div>
  </main>
);

export default IndexPage;
